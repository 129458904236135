import Axios from "axios";

const authRequestInterceptor = (config) => {
  config.headers.Accept = "application/json";
  return config;
};

export const axios = Axios.create({
  baseURL: "https://apiseepalaya.olenepal.org/api/",
  withCredentials: true,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    return Promise.reject(error);
  }
);
